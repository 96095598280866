import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactCodeInput from 'react-code-input';
import PropTypes from 'prop-types';
import Timer from './components/timer';
import Toast from './components/toast';
import Shade from './components/shade';
import l10n from '../../../l10n/en.js';
import methods from '../../../methods.js';

const Challenge = (
  {
    method,
    setMethod,
    code,
    setCode,
    email,
    phone,
    close,
    expirationTime,
    timeRemaining,
    setTimeRemaining,
    lockEndTime,
    resend,
    sending,
    auth,
    authing,
    messageText,
    messageVariant,
    messageTimeout,
    messageNumber,
    hint
  }
) =>
{
  const canChangeMethod = method === methods.SMS || phone;
  const expirationTimeLabel = '5:00';

  const inputStyle =
  {
    borderRadius: '6px',
    border: '1px solid lightgrey',
    margin: '4px',
    paddingLeft: '8px',
    width: '36px',
    height: '42px',
    fontSize: '32px',
    boxSizing: 'border-box'
  };

  return (
    <div align="center">
      <Modal.Dialog>
        <Toast text={messageText} variant={messageVariant} timeout={messageTimeout} number={messageNumber} />
        <Modal.Header closeButton closeLabel="" onHide={close}>
          <Modal.Title>
            {l10n.TITLE}
            {hint}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container align="justify">
            <p>
              {method === methods.EMAIL ? l10n.TOP_TEXT_1_EMAIL : l10n.TOP_TEXT_1_PHONE}
              <strong>
                {method === methods.EMAIL ? email : phone}
              </strong>
              {l10n.TOP_TEXT_2}
              {expirationTimeLabel}
              {l10n.TOP_TEXT_3}
            </p>
          </Container>

          <ReactCodeInput
            id="code"
            inputStyle={inputStyle}
            type="text"
            fields={6}
            onChange={setCode}
          />

          <Container className="mt-3 mb-3">
            {l10n.TIME_REMAINING_LABEL}
            <strong>
              <Timer
                expirationTime={expirationTime}
                timeRemaining={timeRemaining}
                setTimeRemaining={setTimeRemaining}
              />
            </strong>
          </Container>

          <Button
            className="mb-2"
            variant="link"
            disabled={lockEndTime <= timeRemaining}
            onClick={resend}
          >
            {l10n.RESEND_CODE_LINK_LABEL}
          </Button>

          {method === methods.EMAIL ?
            (
              <Container align="justify">
                <p>
                  {l10n.BOTTOM_TEXT_1}
                  {expirationTimeLabel}
                  {l10n.BOTTOM_TEXT_2}
                </p>
              </Container>
            ) : null}
        </Modal.Body>

        <Modal.Footer>
          <Container>
            <Row>
              <Col align="left">
                {canChangeMethod
                  ?
                  (
                    <Button
                      variant="link"
                      onClick={setMethod}
                      className="pl-0"
                    >
                      {method === methods.EMAIL
                        ? l10n.CHANGE_METHOD_LINK_LABEL_SMS
                        : l10n.CHANGE_METHOD_LINK_LABEL_EMAIL}
                    </Button>
                  )
                  : null}
              </Col>
              <Col align="right">
                <Button
                  variant="primary"
                  onClick={auth}
                  disabled={!code || code.length < 6}
                >
                  {l10n.VERIFY_BUTTON_LABEL}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal.Dialog>
      {sending || authing ? <Shade /> : null}
    </div>
  );
};

Challenge.propTypes =
{
  method: PropTypes.number.isRequired,
  setMethod: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  expirationTime: PropTypes.number.isRequired,
  timeRemaining: PropTypes.number.isRequired,
  setTimeRemaining: PropTypes.func.isRequired,
  lockEndTime: PropTypes.number.isRequired,
  resend: PropTypes.func.isRequired,
  auth: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  authing: PropTypes.bool.isRequired,
  messageText: PropTypes.string,
  messageVariant: PropTypes.string,
  messageTimeout: PropTypes.number,
  messageNumber: PropTypes.number,
  hint: PropTypes.string
};

Challenge.defaultProps =
{
  messageText: null,
  messageVariant: 'danger',
  messageTimeout: 0,
  messageNumber: 0,
  hint: null
};

export default Challenge;


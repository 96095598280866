import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Timer = ({ expirationTime, timeRemaining, setTimeRemaining }) =>
{
  const [visible, setVisible] = useState(false);

  const calcTime = () =>
  {
    const time = expirationTime - Date.now();

    return time > 0 ? time : 0;
  };

  useEffect(() =>
  {
    const timer = setInterval(() =>
    {
      const time = calcTime();

      if (time > 0) setVisible(true);

      setTimeRemaining(time);
    },
    1000);

    return () => clearInterval(timer);
  });

  return (
    <span>
      {' '}
      {visible ? new Date(timeRemaining).toISOString().substring(15, 19) : null}
    </span>
  );
};

Timer.propTypes =
{
  expirationTime: PropTypes.number.isRequired,
  timeRemaining: PropTypes.number.isRequired,
  setTimeRemaining: PropTypes.func.isRequired
};

export default Timer;